import { SxProps, Theme } from "@mui/material";
import { CSSProperties } from "react";

export type CustomStyleMap = Record<string, CSSProperties>;

export type BaseButtonProps = {
  text: string;
  buttonSX?: SxProps<Theme>;
  color?: string;
  squareCorners?: boolean;
  roundCorners?: boolean;
  type?: "button" | "reset" | "submit" | undefined;
  fullWidth?: boolean;
  variant?: "text" | "contained" | "outlined";
  href?: string;
};

export const enum Events {
  LEAD_POPUP_OPEN = "lead_popup_open",
  LEAD_POPUP_CLOSE = "lead_popup_close",
  LEAD_INPUT_FOCUS = "lead_input_focus",
  LEAD_INPUT_TYPED = "lead_input_typed",
  LEAD_SUBMITED = "lead",
  PREVIEW_VIDEO_PLAYED = "preview_video_played",
  EXCEPTION = "exception",
  PAGE_LOADED = "page_loaded",
  CHECKOUT_REDIRECT = "initiate_checkout",
  SCROLL_25 = "scroll_25",
  SCROLL_50 = "scroll_50",
  SCROLL_75 = "scroll_75",
  SCROLL_100 = "scroll_100",
  CHAPTER_OPEN = "chapter_open",
  CHAPTER_CLOSE = "chapter_close",
  ENGAGEMENT_1 = "engagement_1",
  ENGAGEMENT_2 = "engagement_2",
  ENGAGEMENT_3 = "engagement_3",
  ENGAGEMENT_4 = "engagement_4",
  ENGAGEMENT_5 = "engagement_5",
  ENGAGEMENT_6 = "engagement_6",
  ENGAGEMENT_7 = "engagement_7",
}

export enum FBPixelNativeEvents {
  LEAD = "Lead",
  PURCHASE = "Purchase",
  PAGEVIEW = "PageView",
  SEARCH = "Search",
  AddToWishlist = "AddToWishlist",
  AddToCart = "AddToCart",
  AddPaymentInfo = "AddPaymentInfo",
  InitiateCheckout = 'InitiateCheckout',

}
