import React from "react";

interface FormSubmitContextType {
  validEmail: undefined | boolean;
}

const FormSubmitContext = React.createContext<FormSubmitContextType>({
  validEmail: undefined,
});

export default FormSubmitContext;
