import { createTheme, responsiveFontSizes } from "@mui/material";
import { primary } from "./palette";

const theme_ = createTheme({
  palette: {
    primary: primary,
    // more custom colors here
  },
  typography: {
    fontFamily: "Open Sans, Roboto, sans-serif",
    h2: {
      fontWeight: 400,
    },
    h5: {
      fontSize: "1.65rem",
    },
    h6: {
      fontWeight: 400,
    },
    body1: {
      fontSize: "0.95rem",
    },
  },
  // TODO: Check this fontWeight
  // TODO: Change sizes to match with phone and tablet.
  // more customizations here
  // custom: {
  //   padding: {
  //     small: {
  //       xs: "8px",
  //       sm: "12px",
  //       md: "16px",
  //     },
  //     big: {
  //       xs: "16px",
  //       sm: "24px",
  //       md: "32px",
  //     },
  //   },
  // },
});

// xs: 0px (extra-small screens, typically mobile devices)
// sm: 600px (small screens, typically small tablets or large mobile devices)
// md: 960px (medium screens, typically tablets or small laptops)
// lg: 1280px (large screens, typically laptops and desktops)
// xl: 1920px (extra-large screens, typically large desktops or TVs)

export const theme = responsiveFontSizes(theme_);

// h1 - 6rem (96px)
// h2 - 3.75rem (60px)
// h3 - 3rem (48px)
// h4 - 2.125rem (34px)
// h5 - 1.5rem (24px)
// h6 - 1.25rem (20px)
// subtitle1 - 1rem (16px)
// subtitle2 - 0.875rem (14px)
// body1 (default) - 1rem (16px)
// body2 - 0.875rem (14px)
// button - 0.875rem (14px)
// caption - 0.75rem (12px)
// overline - 0.75rem (12px)
