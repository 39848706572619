import asPageSection, { PageSectionProps } from "../../shared/types/hoc";
import { Typography, Box } from "@mui/material";
import { TextInput } from "../Inputs/TextInput";
import BaseButton from "../Buttons/BaseButton";
import LeadForm from "../General/Form";

interface LeadsProps extends PageSectionProps {}

function Leads() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: {
            xs: "90%",
            md: "60%",
          },
        }}
      >
        <Typography variant="h3" color="#212326">
          Newsletter!
        </Typography>
        <Typography
          variant="h5"
          color="#212326"
          sx={{ marginTop: 2, marginBottom: 4 }}
        >
          Junta-te à nossa lista de e-mails para receber novidades em primeira
          mão!
        </Typography>
        <LeadForm>
          <TextInput
            roundCorners
            sx={{
              marginBottom: 2,
            }}
          />
          <BaseButton
            fullWidth={false}
            roundCorners
            text="Submeter"
            type="submit"
            buttonSX={{ margin: 0, width: "100%" }}
          />
        </LeadForm>
      </Box>
    </div>
  );
}

const PSLeads = asPageSection<LeadsProps>(Leads);
export default PSLeads;
