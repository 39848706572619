import BaseButton from "./BaseButton";
import { BaseButtonProps, Events } from "../../shared/types";
import { useAnalytics } from "../../hooks/useAnalytics";
import VideoOverlay from "../Course/VideoOverlay";
import { useState } from "react";

declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

export default function PreviewButton(props: BaseButtonProps) {
  const { ...baseButtonProps } = props;
  const [previewVideoOpen, setPreviewVideoOpen] = useState(false);

  const analytics = useAnalytics();

  function handleButtonClick(e: any) {
    setPreviewVideoOpen(true);
    analytics.trackEvent(
      Events.PREVIEW_VIDEO_PLAYED,
      {
        episodeName: "preview",
      },
      false,
      true
    );
  }

  return (
    <>
      <BaseButton {...baseButtonProps} onClick={handleButtonClick} />
      <VideoOverlay
        videoUrl="output.m3u8"
        open={previewVideoOpen}
        setOpen={setPreviewVideoOpen}
      />
    </>
  );
}
