import React, { CSSProperties, useState } from "react";
import FormSubmitContext from "../../contexts/FormContext";
import { submitLeadFromForm } from "../../shared/utils/leadsHandling";
import { useAnalytics } from "../../hooks/useAnalytics";

function LeadForm({
  children,
  className,
  style,
  onSubmit,
}: {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onSubmit?: (valid: boolean, e?: React.FormEvent<HTMLFormElement>) => void;
}) {
  const [validEmail, setValidEmail] = useState<boolean | undefined>(undefined);
  const analytics = useAnalytics();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = submitLeadFromForm(e, analytics.trackEvent);
    setValidEmail(valid);

    if (valid) {
      const button = e?.currentTarget.querySelector("button");
      const input = e?.currentTarget.querySelector("input");

      if (button) button.textContent = "Obrigado!";
      if (input) input.value = "";
    }

    if (onSubmit) onSubmit(valid, e);
  };

  return (
    <FormSubmitContext.Provider value={{ validEmail }}>
      <form
        noValidate
        autoComplete="on"
        style={style}
        className={className}
        onSubmit={handleSubmit}
      >
        {children}
      </form>
    </FormSubmitContext.Provider>
  );
}

export default LeadForm;
