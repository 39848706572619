import { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  ListItemIcon,
  Chip,
  Box,
  ListItemButton,
} from "@mui/material";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Course from "../../shared/classes/course";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import { IChapter, IEpisode } from "../../shared/types/course";
import VideoOverlay from "./VideoOverlay";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Events } from "../../shared/types";

export function CourseCollapsibleList({ course }: { course: Course }) {
  const chapters: any[] = [];

  for (let index = 0; index < course.chapters.length; index++) {
    const chap = course.chapters[index];
    chapters.push(<CourseChapterListItem key={index} chapter={chap} />);
  }

  return (
    <List style={{ padding: 0 }} component="nav">
      {chapters}
    </List>
  );
}

export function CourseEpisodeListItem({ episode }: { episode: IEpisode }) {
  if (!episode.freePreview) {
    return <NotPreviewEpisode title={episode.title} />;
  }

  return <FreePreviewEpisode title={episode.title} />;
}

function NotPreviewEpisode({ title }: { title: string }) {
  return (
    <ListItem>
      <Box display="flex" alignItems="center" width="100%">
        <ListItemIcon
          sx={{
            minWidth: {
              xs: "40px",
              sm: "56px",
            },
          }}
        >
          <OndemandVideoRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: "body1" }}
        />
      </Box>
    </ListItem>
  );
}

function FreePreviewEpisode({ title }: { title: string }) {
  const [previewVideoOpen, setPreviewVideoOpen] = useState(false);
  const analytics = useAnalytics();

  function onClickPlayPreviewEpisode(e: any) {
    setPreviewVideoOpen(true);
    analytics.trackEvent(
      Events.PREVIEW_VIDEO_PLAYED,
      { episodeName: title },
      false,
      true
    );
  }

  return (
    <>
      <ListItemButton onClick={onClickPlayPreviewEpisode}>
        <Box display="flex" alignItems="center" width="100%">
          <ListItemIcon
            sx={{
              minWidth: {
                xs: "40px",
                sm: "56px",
              },
            }}
          >
            <OndemandVideoRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary={title}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#376abc",
              // TODO: Change this for 700 primary
            }}
            primaryTypographyProps={{ variant: "body1" }}
          />
          <Chip
            label="Preview"
            color="primary"
            size="small"
            style={{ marginLeft: 10 }}
          />
        </Box>
      </ListItemButton>
      <VideoOverlay
        videoUrl="output.m3u8"
        open={previewVideoOpen}
        setOpen={setPreviewVideoOpen}
      />
    </>
  );
}

export function CourseChapterListItem({ chapter }: { chapter: IChapter }) {
  const analytics = useAnalytics();

  const [open, setOpen] = useState(false);

  const title = chapter.title;
  const episodes: any[] = [];

  for (let index = 0; index < chapter.episodes.length; index++) {
    const ep = chapter.episodes[index];
    episodes.push(<CourseEpisodeListItem key={index} episode={ep} />);
  }

  const onChapterClickEvent = () => {
    let event;
    if (open) event = Events.CHAPTER_CLOSE;
    else event = Events.CHAPTER_OPEN;
    setOpen(!open);

    analytics.trackEvent(
      event,
      {
        chapterName: chapter.title,
      },
      false,
      true
    );
  };

  return (
    <>
      <ListItem
        button
        onClick={onChapterClickEvent}
        sx={{
          padding: 2,
        }}
      >
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: "h6" }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {episodes}
      </Collapse>
      <Divider />
    </>
  );
}

export function getCollapsibleListHeight(nrChapters: number, size: string) {
  let lineHeight;
  if (size === "xs") {
    lineHeight = 68.8 + 0.8;
  } else {
    lineHeight = 72.8;
  }

  return lineHeight * nrChapters + 24 + "px";
}
