import { Events } from "../types";

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function submitLeadFromForm(
  e: any,
  trackEvent: (
    event: Events,
    props?: Record<string, string> | undefined,
    onlyGoogle?: boolean
  ) => void
): boolean {
  e.preventDefault();
  const email = e.target.querySelector("#email").value;

  const validEmail = validateEmail(email);
  if (!validEmail) {
    return false;
  }

  postLead(email, trackEvent);
  return true;
}

async function postLead(
  email: string,
  trackEvent: (
    event: Events,
    props?: Record<string, string> | undefined,
    onlyGoogle?: boolean,
    isEngagement?: boolean
  ) => void
) {
  const url = new URL("https://api.regwalk.com/leads");
  const params = new URLSearchParams();

  params.append("tag", "Lead");
  params.append("email", email);

  url.search = params.toString();
  try {
    const response = await fetch(url, {
      headers: { "Content-Type": "application/json" },
    });
    const data = await response.json();
    trackEvent(
      Events.LEAD_SUBMITED,
      { response: data, lead: email },
      false,
      true
    );
  } catch (error: unknown) {
    if (error instanceof Error) {
      trackEvent(
        Events.EXCEPTION,
        { message: error.message, type: "post_lead", email_tried: email },
        true
      );
    }
  }
}
