import CourseBanner from "./CourseBanner";
import Course from "../../shared/classes/course";
import ButtonOpensLeadsPopup from "../Buttons/ButtonOpensLeadsPopup";
import { PageSectionProps } from "../../shared/types/hoc";
import asPageSection from "../../shared/types/hoc";
import { Typography } from "@mui/material";
import { TextInput } from "../Inputs/TextInput";
import BaseButton from "../Buttons/BaseButton";
import LeadForm from "../General/Form";
import RedirectToCheckoutButton from "../Buttons/RedirectToCheckoutButton";
import PreviewButton from "../Buttons/PreviewButton";

interface CourseBannerWithCtaProps extends PageSectionProps {
  overlayOpacity?: number;
  course: Course | undefined;
  bannerImgSrc: string;
}

function CourseBannerWithCta({
  overlayOpacity = 0.3,
  course,
  bannerImgSrc,
}: CourseBannerWithCtaProps) {
  // FIXME: remove any typehint
  return (
    <CourseBanner
      bannerImgSrc={bannerImgSrc}
      course={course}
      overlayOpacity={overlayOpacity}
    >
      <Typography
        variant="h6"
        color="white"
        className="hide-on-web"
        sx={{
          lineHeight: 1.35,
          margin: "2% 4% 10% 4%",
          textAlign: "center",
        }}
      >
        Explora possíveis percursos de carreira em Assuntos Regulamentares.
      </Typography>
      <RedirectToCheckoutButton
        roundCorners
        text="Inscreve-te!"
        href="https://regwalk.thinkific.com/enroll/2411331?et=paid"
      />
      <PreviewButton
        roundCorners
        // variant="outlined"
        color="#d29738"
        text="Preview Curso"
      ></PreviewButton>
      {/* <LeadForm
        className="hide-on-web"
        // style={{ width: "83%" }}
        // onSubmit={onSubmitLead}
      >
        <Typography
          variant="h6"
          color="white"
          sx={{
            lineHeight: 1.35,
            margin: "2% 4% 10% 4%",
            textAlign: "center",
          }}
        >
          Junta-te à nossa lista de e-mails para receber novidades em primeira
          mão!
        </Typography>
        <TextInput
          roundCorners
          sx={{
            marginBottom: 2,
          }}
        />
        <BaseButton
          roundCorners
          text="Junta-te a nós!"
          fullWidth
          type="submit"
        />
      </LeadForm>

      <div className={"hide-on-mobile"}>
        <RedirectToCheckoutButton
          roundCorners
          text="Inscreve-te!"
          href="https://regwalk.thinkific.com/enroll/2411331?et=paid"
        />
      </div> */}
    </CourseBanner>
  );
}

const PSCourseBannerWithCta =
  asPageSection<CourseBannerWithCtaProps>(CourseBannerWithCta);
export default PSCourseBannerWithCta;
