import { ICourse, IChapter, IDetails } from "../types/course";

class Course implements ICourse {
  constructor(
    public title: string,
    public description: string,
    public chapters: IChapter[],
    public img: string,
    public details: IDetails
  ) {}

  static async fromJSON(jsonFilePath: string): Promise<Course> {
    const jsonData = await import(`../../${jsonFilePath}`);
    const { title, description, chapters, img, details } = jsonData as ICourse;
    return new Course(title, description, chapters, img, details);
  }
}

export default Course;
