import React from "react";
import Course from "../../shared/classes/course";
import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Theme,
  Typography,
} from "@mui/material";
import OndemandVideoRoundedIcon from "@mui/icons-material/OndemandVideoRounded";
import { IDetails } from "../../shared/types/course";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { getRootFontSize, remToPx } from "../../shared/utils/loadingStates";
import Price from "../CourseDetailsSectionItem/Price";
import Duration from "../CourseDetailsSectionItem/Duration";

const styles = {
  media: {
    paddingTop: "56.25%", // 16:9 aspect ratio
  },
  icon: {
    marginRight: 4,
  },
  detailRow: {
    marginBottom: 14,
  },
};

function CourseCard({ course }: { course: Course }) {
  return (
    <Card
      sx={{
        // width: "98%",
        maxWidth: { xs: "100%", md: 400 },
        minWidth: { md: "100%" },
        // margin: "0 auto", // center the card horizontally
      }}
    >
      <CardMedia sx={styles.media} image={course.img} title={course.title} />
      <CardContent
        style={{
          // padding-bottom 30px - 14px from the last detail section margin.
          padding: "30px 30px 16px 30px",
        }}
      >
        <Typography variant="h5">Detalhes do curso</Typography>
        <Divider
          sx={{
            width: "20%",
            bgcolor: "black",
            marginTop: 2,
            marginBottom: 2,
          }}
        />
        <DetailsSection details={course.details} />
      </CardContent>
    </Card>
  );
}

function DetailsSection({ details }: { details: IDetails }) {
  return (
    <>
      {details.classes && (
        <Typography
          display="flex"
          alignItems="center"
          variant="h6"
          style={styles.detailRow}
        >
          <OndemandVideoRoundedIcon
            style={{ paddingRight: "16px", fontSize: "2rem" }}
          />
          {details.classes} aulas
        </Typography>
      )}
      {details.QnA && (
        <Typography
          display="flex"
          alignItems="center"
          variant="h6"
          style={styles.detailRow}
        >
          <FontAwesomeIcon
            icon={faComment}
            style={{ paddingRight: "16px", fontSize: "2rem" }}
          />
          {details.QnA}
        </Typography>
      )}
      {details.pdf_support && (
        <Typography
          display="flex"
          alignItems="center"
          variant="h6"
          style={styles.detailRow}
        >
          <FontAwesomeIcon
            icon={faFilePdf}
            style={{ paddingRight: "16px", fontSize: "2rem" }}
          />
          {details.pdf_support}
        </Typography>
      )}
      <Duration duration={details.duration} />
      <Price price={10} priceBeforeDiscount={20} />
    </>
  );
}

export function estimateCourseCardHeight(detailRowCount: number, theme: Theme) {
  // 2. Padding inside the CardContent
  const paddingHeight = 30 + 16; // top and bottom padding

  // 3. Typography height (Detalhes do curso)
  const titleFontSize = theme.typography.h5.fontSize as string;
  const titleHeight =
    remToPx(titleFontSize) * (theme.typography.h5.lineHeight as number);

  // 4. Divider height
  const dividerHeight = 2 * getRootFontSize() + 0.8; // assuming divider height of 4px

  // 5. DetailsSection height
  const detailRowSpacing = 14;
  const detailsSectionHeight = detailRowCount * (32 + detailRowSpacing);

  // Total height
  const totalHeight =
    "calc((9/16)*98vw + " +
    // cardMediaHeight +
    (paddingHeight + titleHeight + dividerHeight + detailsSectionHeight + 24) +
    "px";

  return totalHeight;
}

export default CourseCard;
