import React, { useContext, useEffect, useState, useRef } from "react";

import { Box, Tooltip } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import FormSubmitContext from "../../contexts/FormContext";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Events } from "../../shared/types";

// TODO: Event vai para aqui? Pou para o parent?

export function TextInput({
  roundCorners = false,
  squareCorners = false,
  sx = {},
}) {
  const formContext = useContext(FormSubmitContext);
  const [errorActive, setErrorActive] = useState(false);
  const hasFocusedInput = useRef(false);
  const hasTyped = useRef(false);

  const analytics = useAnalytics();

  function handleFocus() {
    if (hasFocusedInput.current) return;
    analytics.trackEvent(Events.LEAD_INPUT_FOCUS, {}, false, true);
    hasFocusedInput.current = true;
  }

  function handleTyping() {
    if (hasTyped.current) return;
    analytics.trackEvent(Events.LEAD_INPUT_TYPED, {}, false, true);
    hasTyped.current = true;
  }

  useEffect(() => {
    if (formContext.validEmail === false) {
      setErrorActive(true);
    } else {
      setErrorActive(false);
    }
  }, [formContext.validEmail]);

  function getBorderRadius() {
    if (squareCorners) return "0px";
    if (roundCorners) return "100px";
    return "10px";
  }

  return (
    <Tooltip
      arrow
      title={
        <Box display="flex" alignItems="center">
          <ErrorIcon fontSize="small" sx={{ marginRight: 0.5 }} />
          E-mail no formato errado.
        </Box>
      }
      open={errorActive}
      placement="top"
      //   style={{ backgroundColor: "red" }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "error.main",
            color: "white",
            borderRadius: 1,
            fontSize: "0.9rem",
            padding: "0.5rem",
          },
        },
        arrow: {
          sx: {
            top: "98.5%",
            position: "relative",
            color: "error.main",
          },
        },
      }}
    >
      <Box
        id="email"
        component="input"
        type="email"
        placeholder="Email"
        autoComplete="email"
        required
        formNoValidate={true}
        onFocus={handleFocus}
        onInput={handleTyping}
        // autoComplete="email"
        sx={{
          ...sx,
          boxSizing: "border-box",
          width: "100%",
          padding: "0.9rem 1rem",
          border: "2px solid #d7d6d6",
          borderRadius: getBorderRadius(),
          fontSize: "17px",
          outline: "none",
          "&:focus, &:hover, &:active": {
            borderColor: "#4585eb",
          },
        }}
      />
    </Tooltip>
  );
}
