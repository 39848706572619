import { usePageContext } from "../contexts/PageContext";
import { useGlobalContext } from "../contexts/GlobalContext";
import { Events } from "../shared/types";
import { FBPixelNativeEvents } from "../shared/types";
import { isDevEnv } from "../shared/utils/deviceType";
import { useEngagementContext } from "../contexts/EngagementContext";

function snakeToPascalCase(snake: string): string {
  return snake
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("");
}

export function useAnalytics() {
  const pageValues = usePageContext();
  const globalValues = useGlobalContext();
  if (!pageValues || !globalValues) {
    throw new Error(
      "useAnalytics must be used within a GlobalProvider and pageProvider"
    );
  }

  const { deviceType, ...othersG } = globalValues;
  const { pageType, pageName, ...othersP } = pageValues;

  const { incrementActionCount } = useEngagementContext();

  const trackEvent = (
    event: Events,
    props?: Record<string, string | number | undefined>,
    onlyGoogle = false,
    isEngagement = false
  ) => {
    // Use pageType and courseName in your analytics logic

    const propsEnriched = {
      deviceType,
      pageType,
      pageName,
      ...props,
    };

    if (isEngagement) incrementActionCount();

    if (isDevEnv()) {
      console.log(event, propsEnriched);
    } else {
      window.gtag("event", event, propsEnriched);
      if (!onlyGoogle) sendPixelEvent(event, propsEnriched);
    }
  };

  function setUserProperties(
    props: Record<string, string | number | undefined>
  ) {
    if (isDevEnv()) console.log("setting user properties", props);
    else window.gtag("set", "user_properties", props);
  }

  return {
    trackEvent,
    setUserProperties,
  };
}

function sendPixelEvent(event: Events, props: any) {
  const pixelEvent = snakeToPascalCase(event);
  if (
    (Object.values(FBPixelNativeEvents) as Array<string>).includes(pixelEvent)
  ) {
    window.fbq("track", pixelEvent, props);
  } else {
    window.fbq("trackCustom", pixelEvent, props);
  }
}
