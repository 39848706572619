import React from "react";
import { Typography } from "@mui/material";
import Course from "../../shared/classes/course";

const classes: any = {
  fullWidthImage: {
    position: "relative",
    width: "100%",
    height: 580,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center 29%",
    backgroundSize: "cover",
    overflow: "hidden",
  },
  myoverlay: {
    position: "absolute",
    zIndex: 1,
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.4)",
  },
  courseSubtitle: {
    lineHeight: 1.35,
    padding: "2% 8% 4% 8%",
    textAlign: "center",
  },
  textContainer: {
    position: "absolute",
    width: "80%",
    textAlign: "center",
    zIndex: 2,
  },
};

interface CourseBannerProps {
  overlayOpacity?: number;
  course: Course | undefined;
  bannerImgSrc: string;
  children?: React.ReactNode;
}

export default function CourseBanner({
  overlayOpacity = 0.3,
  course,
  bannerImgSrc,
  children,
}: CourseBannerProps) {
  return (
    <div
      style={{
        ...classes.fullWidthImage,
        backgroundImage: `url(${bannerImgSrc})`,
      }}
    >
      <div style={classes.myoverlay} />
      {course && (
        <div style={classes.textContainer}>
          <Typography variant="h2" color="white">
            {course.title}
          </Typography>
          <Typography
            className="hide-on-mobile"
            variant="h6"
            color="white"
            sx={classes.courseSubtitle}
          >
            {course.description}
          </Typography>
          {children}
        </div>
      )}
    </div>
  );
}
