import React from "react";

export const primary = {
  50: "#a2c2f5",
  100: "#8fb5f3",
  200: "#7ca9f1",
  300: "#6a9def",
  400: "#5791ed",
  500: "#4585eb", // main
  600: "#3e77d3",
  700: "#376abc",
  800: "#305da4",
  900: "#294f8d",
};

interface ColorPaletteProps {
  palette: {
    [key: string]: string;
  };
}

const useStyles: any = {
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    // padding: theme.spacing(3),
  },
  colorBox: {
    width: 100,
    height: 100,
    // margin: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export const ColorPalette: React.FC<ColorPaletteProps> = ({ palette }) => {
  // const classes = useStyles();

  return (
    <div style={useStyles.root}>
      {Object.entries(palette).map(([name, color]) => (
        <div
          key={name}
          style={{ ...useStyles.colorBox, backgroundColor: color }}
        >
          <span>{name}</span>
        </div>
      ))}
    </div>
  );
};
