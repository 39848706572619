import { Typography, Box } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

const styles = {
  detailRow: {
    marginBottom: 14,
  },
};

export default function Duration({ duration }: { duration?: string }) {
  if (!duration || duration === "") return <></>;

  return (
    <Box display="flex" alignItems="center" style={styles.detailRow}>
      <FontAwesomeIcon
        icon={faClock}
        style={{ paddingRight: "16px", fontSize: "2rem" }}
        // sx={{ paddingRight: "12px", fontSize: "2.4rem" }}
      />
      <Typography variant="h6">{duration}</Typography>
    </Box>
  );
}
