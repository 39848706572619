export function getRootFontSize() {
  const rootFontSize = window
    ? parseFloat(
        getComputedStyle(document.documentElement).getPropertyValue("font-size")
      )
    : 16; // fallback to 16px if running in a non-browser environment

  return rootFontSize;
}

export function remToPx(rem: string) {
  return parseFloat(rem) * getRootFontSize();
}
