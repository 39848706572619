import React, { useRef, useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { useAnalytics } from "../../hooks/useAnalytics";
import { Events } from "../../shared/types";
import Hls from "hls.js"; // Import hls.js

interface VideoOverlayProps {
  videoUrl: string;
  posterUrl?: string;
  open: boolean;
  setOpen: (s: boolean) => void;
}

const VideoOverlay: React.FC<VideoOverlayProps> = ({
  videoUrl,
  posterUrl,
  open,
  setOpen,
}) => {
  const startPlayingTime = useRef(0);
  const pauseTime = useRef(0);
  const analytics = useAnalytics();
  const [videoRefState, setVideoRefState] = useState<HTMLVideoElement | null>(
    null
  );
  const handleClose = () => {
    setOpen(false);
  };

  function handleError(e: React.SyntheticEvent<HTMLVideoElement>) {
    const error = e.currentTarget.error;
    analytics.trackEvent(Events.EXCEPTION, {
      code: error?.code,
      message: error?.message,
      type: "preview_failed",
    });
  }

  function onVideoOpen(e: any) {
    startPlayingTime.current = performance.now();
  }

  function onPause(e: any) {
    pauseTime.current = performance.now();
  }

  function onPlay(e: any) {
    if (pauseTime.current === 0) return;
    const timePaused = performance.now() - pauseTime.current;
    startPlayingTime.current += timePaused;
    pauseTime.current = 0;
  }

  useEffect(() => {
    const video = videoRefState;
    if (open || video === null) return;

    const percentagePlayed = (video.currentTime / video.duration) * 100;

    const finishTime = performance.now();

    if (pauseTime.current !== 0) {
      const timePaused = finishTime - pauseTime.current;
      startPlayingTime.current += timePaused;
    }
    const realWatchTime = (finishTime - startPlayingTime.current) / 1000;

    analytics.trackEvent(
      Events.PREVIEW_VIDEO_PLAYED,
      {
        percentagePlayed: percentagePlayed.toFixed(2),
        realWatchTime: ((realWatchTime / video.duration) * 100).toFixed(2),
        video: "O que é Assuntos Regulamentares?",
      },
      false,
      true
    );

    pauseTime.current = 0;
  }, [open]);

  useEffect(() => {
    const video = videoRefState;
    if (!video) return;

    if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = videoUrl;
      video.addEventListener("loadedmetadata", function () {
        video.play();
      });
      return;
    }

    const hls = new Hls();

    if (Hls.isSupported()) {
      hls.loadSource(videoUrl);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
      return () => {
        hls.destroy();
      };
    }
    video.addEventListener("loadedmetadata", function () {
      video.play();
    });

    return;
  }, [videoRefState, videoUrl]);

  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <video
          ref={(ref) => setVideoRefState(ref)}
          controls
          poster={posterUrl}
          width="100%"
          height="100%"
          onCanPlay={onVideoOpen}
          onPause={onPause}
          onPlay={onPlay}
          onError={handleError}
          style={{ objectFit: "contain" }}
        >
          <source src="test.mp4" type="video/mp4" />
        </video>
      </Dialog>
    </Box>
  );
};

export default VideoOverlay;
