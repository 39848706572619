import React from "react";
import {
  CourseCollapsibleList,
  getCollapsibleListHeight,
} from "./CourseCollapsibleList";
import Course from "../../shared/classes/course";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import CourseCard, { estimateCourseCardHeight } from "./CourseCard";
import asPageSection, { PageSectionProps } from "../../shared/types/hoc";

interface CourseDetailsSectionProps extends PageSectionProps {
  course: Course | undefined;
  nrChapters: number;
}

function CourseDetailsSection({
  course,
  nrChapters,
}: CourseDetailsSectionProps) {
  const theme = useTheme();

  // TODO: add loading animation

  const loadingComponent = (
    <Box
      sx={{
        height: {
          xs:
            estimateCourseCardHeight(3, theme) +
            " + " +
            getCollapsibleListHeight(nrChapters, "xs") +
            ")",
          md: estimateCourseCardHeight(3, theme) + ")",
        },
      }}
    ></Box>
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: { xs: "98%", md: "95%", lg: "80%" } }}>
          <Typography variant="h3" style={{ marginBottom: 20 }}>
            Conteúdo
          </Typography>
          <Grid container spacing={3}>
            {course ? (
              <>
                <Grid item xs={12} md={7} lg={8} style={{ order: 1 }}>
                  <CourseCollapsibleList course={course} />
                </Grid>
                <Grid item xs={12} md={5} lg={4} style={{ order: 2 }}>
                  <CourseCard course={course} />
                </Grid>
              </>
            ) : (
              loadingComponent
            )}
          </Grid>
        </Box>
      </div>
    </>
  );
}

const PSCourseDetailsSection = asPageSection(CourseDetailsSection);
export default PSCourseDetailsSection;
