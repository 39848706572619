import { EngagementProvider } from "./contexts/EngagementContext";
import PageProvider from "./contexts/PageContext";
import CourseLandingPage from "./pages/CourseLandingPage/CourseLandingPage";

function App() {
  return (
    <PageProvider
      pageType="Course - Landing Page"
      pageName="PT - Intro to Regulatory Affairs"
    >
      <EngagementProvider>
        <CourseLandingPage />
      </EngagementProvider>
    </PageProvider>
  );
}

export default App;
