import React from "react";
import { Button, SxProps, Theme } from "@mui/material";
import { BaseButtonProps } from "../../shared/types";

function BaseButton({
  text,
  buttonSX,
  color = "primary",
  squareCorners,
  roundCorners,
  type,
  fullWidth,
  variant = "contained",
  onClick,
  href = "",
}: BaseButtonProps & {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) {
  // function getThemeColor(theme: Theme, color: string) {
  //   const themeColor = theme.palette[color]?.main;
  //   return themeColor ? themeColor : color;
  // }

  function getBorderRadius() {
    if (squareCorners) return "0px";
    if (roundCorners) return "100px";
    return "10px";
  }

  // TODO: Make hover color dynamic
  // TODO: Make backgroundcolor accept both theme colors or hex values
  const sx: SxProps<Theme> = {
    borderRadius: getBorderRadius(),
    backgroundColor: color,
    textTransform: "none",
    typography: "h6",
    boxShadow: "none",
    // "&:hover": {
    //   backgroundColor: (theme) => darken(color, 0.5),
    // },
    padding: "0.725rem 34px", // Adjust the padding values as needed
    margin: 1,
    width: {
      xs: "100%", // full width on xs devices
      sm: fullWidth ? "100%" : "auto", // auto width on sm devices and above
    },
    ...buttonSX,
  };
  const buttonProps = { type, onClick, variant, sx, href };

  return <Button {...buttonProps}>{text}</Button>;
}

export default BaseButton;
