interface ExtendedNavigator extends Navigator {
  deviceMemory?: number;
}

export function getDeviceProps() {
  const extendedNavigator = navigator as ExtendedNavigator;

  const devicePixelRatio = window.devicePixelRatio;
  // eslint-disable-next-line no-restricted-globals
  const screenWidth = screen.width;
  // eslint-disable-next-line no-restricted-globals
  const screenHeight = screen.height;
  const deviceLanguage = extendedNavigator.language;

  const isTouchDevice =
    "ontouchstart" in window || extendedNavigator.maxTouchPoints > 0;

  const hardwareConcurrency = extendedNavigator.hardwareConcurrency || 1;
  const memory = extendedNavigator.deviceMemory || 1;
  // TODO: Get user agent

  // Detect device type
  let deviceType = "desktop";
  if (isTouchDevice) {
    if (Math.min(screenWidth, screenHeight) < 768) {
      deviceType = "mobile";
    } else {
      deviceType = "tablet";
    }
  }

  // Classify device tier based on screen size, device pixel ratio, hardware concurrency, and memory
  let tier = "medium";
  if (deviceType === "desktop") {
    if (
      screenWidth * devicePixelRatio >= 1920 &&
      hardwareConcurrency >= 8 &&
      memory >= 8
    ) {
      tier = "high";
    } else if (
      screenWidth * devicePixelRatio >= 1366 &&
      hardwareConcurrency >= 4 &&
      memory >= 4
    ) {
      tier = "medium";
    } else {
      tier = "low";
    }
  } else if (deviceType === "mobile") {
    if (devicePixelRatio >= 3 && hardwareConcurrency >= 4 && memory >= 4) {
      tier = "high";
    } else if (
      devicePixelRatio >= 2 &&
      hardwareConcurrency >= 2 &&
      memory >= 2
    ) {
      tier = "medium";
    } else {
      tier = "low";
    }
  } else if (deviceType === "tablet") {
    if (devicePixelRatio >= 2 && hardwareConcurrency >= 4 && memory >= 4) {
      tier = "high";
    } else if (
      devicePixelRatio >= 1.5 &&
      hardwareConcurrency >= 2 &&
      memory >= 2
    ) {
      tier = "medium";
    } else {
      tier = "low";
    }
  }

  return {
    deviceType,
    tier,
    screenHeight,
    screenWidth,
    devicePixelRatio,
    memory,
    hardwareConcurrency,
    deviceLanguage,
  };
}

export function isDevEnv() {
  return process.env.NODE_ENV === "development";
}
