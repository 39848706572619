import { useState, useEffect, useRef } from "react";
import { useAnalytics } from "./useAnalytics";
import { Events } from "../shared/types";

// Custom Hook
export const useScrollTracker = () => {
  const thresholds = [25, 50, 75, 100];
  const [scrollPercent, setScrollPercent] = useState<number>(0);
  const trackedThresholds = useRef(new Set<number>());
  const analytics = useAnalytics();

  const getEvent = (threshold: number) => {
    switch (threshold) {
      case 25:
        return Events.SCROLL_25;
      case 50:
        return Events.SCROLL_50;
      case 75:
        return Events.SCROLL_75;
      case 100:
        return Events.SCROLL_100;
      default:
        return null;
    }
  };

  const trackScrollEvent = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    const scrolled = (scrollTop / (scrollHeight - clientHeight)) * 100;

    setScrollPercent(scrolled);

    thresholds.forEach((threshold) => {
      if (scrolled >= threshold && !trackedThresholds.current.has(threshold)) {
        // Trigger event for reaching threshold
        const event = getEvent(threshold);
        if (event === null) return;

        analytics.trackEvent(event, {}, false, true);
        // Example: sendEventToAnalytics(`Scrolled ${threshold}%`);
        trackedThresholds.current.add(threshold);
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", trackScrollEvent);

    return () => {
      window.removeEventListener("scroll", trackScrollEvent);
    };
  }, []);

  return scrollPercent;
};
