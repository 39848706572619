import BaseButton from "./BaseButton";
import { BaseButtonProps, Events } from "../../shared/types";
import { useAnalytics } from "../../hooks/useAnalytics";

declare global {
  interface Window {
    gtag: any;
    fbq: any;
  }
}

export default function RedirectToCheckoutButton(props: BaseButtonProps) {
  const { ...baseButtonProps } = props;

  const analytics = useAnalytics();

  function handleButtonClick(e: any) {
    analytics.trackEvent(Events.CHECKOUT_REDIRECT, {}, false, true);
    if (!props.href) return;
    window.location.href = props.href;
  }

  return (
    <>
      <BaseButton
        {...baseButtonProps}
        onClick={handleButtonClick}
        href="https://regwalk.thinkific.com/enroll/2411331?et=paid"
      />
    </>
  );
}
