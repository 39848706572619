import { ReactNode, useContext, createContext, useEffect } from "react";

const getDeviceType = () => {
  const width = window.innerWidth;

  if (width <= 425) {
    return "mobile";
  } else if (width <= 768) {
    return "tablet";
  } else {
    return "desktop";
  }
};

interface GlobalContextType {
  deviceType: string;
  // sessionStartTime?: number;
}

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

function GlobalProvider({ children }: GlobalProviderProps) {
  const value = {
    deviceType: getDeviceType(),
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export default GlobalProvider;
