import { Typography, Box } from "@mui/material";
import React from "react";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

const styles = {
  detailRow: {
    marginBottom: 14,
  },
};

export default function Price({
  price,
  priceBeforeDiscount,
}: {
  price: number;
  priceBeforeDiscount: number;
}) {
  if (!price) return <></>;

  return (
    <Box display="flex" alignItems="center" style={styles.detailRow}>
      <LocalOfferOutlinedIcon
        sx={{ paddingRight: "12px", fontSize: "2.4rem" }}
      />
      <Typography variant="h6" fontWeight="600" color="#ee6a6a">
        € {price}.00
      </Typography>
      <Typography
        fontSize="14"
        fontWeight="500"
        sx={{ textDecoration: "line-through", marginLeft: 1, color: "grey" }}
      >
        €{priceBeforeDiscount}.00
      </Typography>
    </Box>
  );
}
