import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { useAnalytics } from "../hooks/useAnalytics";
import { Events } from "../shared/types";

interface EngagementContextType {
  actionCount: number;
  incrementActionCount: () => void;
  highestLevelLogged: React.MutableRefObject<number>;
}

const EngagementContext = createContext<EngagementContextType>({
  actionCount: 0,
  incrementActionCount: () => {},
  highestLevelLogged: { current: 0 },
});

const getEngagementEvent = (level: number) => {
  switch (level) {
    case 1:
      return Events.ENGAGEMENT_1;
    case 2:
      return Events.ENGAGEMENT_2;
    case 3:
      return Events.ENGAGEMENT_3;
    case 4:
      return Events.ENGAGEMENT_4;
    case 5:
      return Events.ENGAGEMENT_5;
    case 6:
      return Events.ENGAGEMENT_6;
    default:
      return null;
  }
};

const maybeLogEvent = (
  level: number,
  analytics: {
    trackEvent: (
      arg0: Events,
      arg1?: {},
      arg2?: boolean,
      arg3?: boolean
    ) => void;
  }
) => {
  const event = getEngagementEvent(level);
  if (event === null) return;

  analytics.trackEvent(event);
};

export const EngagementProvider = ({ children }: { children: any }) => {
  const [actionCount, setActionCount] = useState(0);
  const highestLevelLogged = useRef(0);
  const incrementActionCount = () => {
    setActionCount((prevCount) => prevCount + 1);
  };
  const analytics = useAnalytics();

  useEffect(() => {
    const thresholds = [4, 8, 12, 16, 20, 25];

    let currentLevel = 0;
    for (let i = 0; i < thresholds.length; i++) {
      if (actionCount >= thresholds[i]) {
        currentLevel = i + 1;
      }
    }

    // Log the engagement level if it's higher than previously logged
    if (currentLevel > highestLevelLogged.current) {
      highestLevelLogged.current = currentLevel;
      maybeLogEvent(currentLevel, analytics);
      // Send event to analytics or other logging mechanism
    }
  }, [actionCount, analytics]);

  return (
    <EngagementContext.Provider
      value={{ actionCount, incrementActionCount, highestLevelLogged }}
    >
      {children}
    </EngagementContext.Provider>
  );
};

export const useEngagementContext = () => {
  return useContext(EngagementContext);
};
