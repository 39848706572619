import PSCourseBannerWithCta from "../../components/Course/CourseBannerWithCta";
import PSCourseDetailsSection from "../../components/Course/CourseDetailsSection";
import PSLeads from "../../components/PageSections/PSLeads";
import PSFooter from "../../components/General/Footer";
import { useCourseData } from "../../hooks/useCourseData";
import { useScrollTracker } from "../../hooks/useScrollTracker";

export default function CourseLandingPage() {
  const { course } = useCourseData();
  useScrollTracker();

  return (
    <>
      <PSCourseBannerWithCta course={course} bannerImgSrc="banner-img.webp" />
      <PSCourseDetailsSection
        withPaddingTop
        withPaddingBottom
        course={course}
        bgColor="#F8F2E9"
        nrChapters={6}
      />
      <PSLeads withPaddingTop withPaddingBottom />
      <PSFooter />
    </>
  );
}
