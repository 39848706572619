import React, { ComponentType } from "react";
import { Box } from "@mui/material";

export interface PageSectionProps {
  withPaddingTop?: boolean;
  withPaddingBottom?: boolean;
  bgColor?: string;
}

function asPageSection<P extends PageSectionProps>(
  WrappedComponent: ComponentType<P>
): React.FC<P & PageSectionProps> {
  return function EnhancedComponent(props: P & PageSectionProps) {
    // Destructure your extra props here
    const { withPaddingTop, withPaddingBottom, bgColor, ...otherProps } = props;

    const extraBoxProps = {
      paddingTop: withPaddingTop ? 10 : undefined,
      paddingBottom: withPaddingBottom ? 4 : undefined,
      backgroundColor: bgColor,
    };

    // Pass the extra props to the Box component and wrap the WrappedComponent with it
    return (
      <Box {...extraBoxProps}>
        <WrappedComponent {...(otherProps as P)} />
      </Box>
    );
  };
}

export default asPageSection;
