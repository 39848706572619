import { ReactNode, useContext } from "react";

import { createContext } from "react";

interface PageContextType {
  pageType: string;
  pageName: string;
}

interface PageProviderProps {
  children: ReactNode;
  pageType: string;
  pageName: string;
}

const PageContext = createContext<PageContextType | undefined>(undefined);

const PageProvider: React.FC<PageProviderProps> = ({
  children,
  pageType,
  pageName,
}) => {
  const value = {
    pageType,
    pageName,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export function usePageContext() {
  return useContext(PageContext);
}

export default PageProvider;
